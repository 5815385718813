import {
  JpModal,
  JpText,
  JpAvatar,
  JpButton,
  SweetModal,
  getUserOperatingSystem
} from '@Intelli/utilities'
import WalletAPI from '../../services/API'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { useState } from 'react'

const operatingColors = {
  WINDOWS: {
    color: 'info'
  },
  MACOS: {
    color: 'dark'
  },
  LINUX: {
    color: 'dark'
  }
}

const DownloadApp = ({ modalDownload, setModalDownload }) => {
  const API = new WalletAPI()
  const { t } = useTranslation()
  const os = getUserOperatingSystem()

  const { data: appsDataArray, isLoading: appsDataArrayIsLoading } = useQuery(
    ['appsDataArray'],
    () => API.getDashboardApps(),
    {
      enabled: modalDownload
    }
  )
  const [showApps, setShowApps] = useState(false)

  return (
    <>
      <JpModal
        isOpen={modalDownload}
        toggle={() => {
          setShowApps(false)
          setModalDownload(false)
        }}
        headerContent={<>{t('downloadApp')}</>}
        bodyContent={
          <>
            {/*  <JpText
              type="p-text"
              className={'mt-1'}
              children={t('operatingSystem')}
            /> */}

            <Row>
              {appsDataArray?.map((item, index) => {
                if (item?.name == os.toUpperCase() && os && !showApps) {
                  return (
                    <Col
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ height: 250 }}
                      key={index}
                    >
                      <JpAvatar
                        icon={<i class={`${item?.icon}`}></i>}
                        size="xl"
                        color={`${operatingColors[item?.name]?.color}`}
                        className={'mb-50'}
                      />
                      <JpButton
                        type="download"
                        text={t(`views.operatingSystem.${item?.name}`)}
                        options={{
                          customIcon: (
                            <i class="fa-solid fa-download me-75"></i>
                          ),
                          onClick: () => {
                            setModalDownload(false)
                            window.location.href = `${item?.url}`
                            setShowApps(false)
                            SweetModal(
                              'success',
                              `${t('modals.success')}`,
                              `${t('modals.downloadApp')}`,
                              t('Ok')
                            )
                          },
                          iconPosition: 'left'
                        }}
                      />
                    </Col>
                  )
                }
                if (showApps) {
                  return (
                    <Col
                      className="d-flex flex-column align-items-center justify-content-center"
                      style={{ height: 250 }}
                      key={index}
                    >
                      <JpAvatar
                        icon={<i class={`${item?.icon}`}></i>}
                        size="xl"
                        color={`${operatingColors[item?.name]?.color}`}
                        className={'mb-50'}
                      />
                      <JpButton
                        type="download"
                        text={t(`views.operatingSystem.${item?.name}`)}
                        options={{
                          customIcon: (
                            <i class="fa-solid fa-download me-75"></i>
                          ),
                          onClick: () => {
                            setModalDownload(false)
                            window.location.href = `${item?.url}`
                            setShowApps(false)
                            SweetModal(
                              'success',
                              `${t('modals.success')}`,
                              `${t('modals.downloadApp')}`,
                              t('Ok')
                            )
                          },
                          iconPosition: 'left'
                        }}
                      />
                    </Col>
                  )
                }
              })}

              <JpButton
                color="link"
                type="add"
                className="p-1 text-start"
                text={!showApps ? t(`seeAll`):t(`seeRecommended`)}
                options={{
                  iconless:true,
                  onClick: () => {
                    setShowApps(!showApps)
                  }
                }}
              />
            </Row>
          </>
        }
      />
    </>
  )
}

export default DownloadApp
