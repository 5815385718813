import {
  dateLocalizer,
  JpCurrencyNumber,
  JpTableV1,
  JpText,
  parseMinutesToHours
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import WalletAPI from '../../services/API'
import TimesDetailGraphs from './TimesDetailGraphs'
import useSocket from '../../utility/hooks/useSocket'
import disabledRefetchSocket from '../../utility/disabledRefetchSocket'

const TimesDetail = () => {
  const { t } = useTranslation()
  const API = new WalletAPI()
  const { date } = useParams()

  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [queries, setQueries] = useState('')
  const [sortBy, setSortBy] = useState('clock_in__desc')

  const tabCols = [
    {
      name: t('views.times.clockIn'),
      sortable: 'clock_in',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            className="ms-50"
            children={
              row?.clock_in
                ? dateLocalizer.getMomentShortTime(row?.clock_in)
                : '-'
            }
          />
        )
      }
    },
    {
      name: t('views.times.clockOut'),
      sortable: 'clock_out',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            children={
              row?.clock_out
                ? dateLocalizer.getMomentShortTime(row?.clock_out)
                : '-'
            }
          />
        )
      }
    },
    {
      name: t('views.times.timeWorked'),
      sortable: 'work_time',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            className="text-green"
            children={`${
              row.work_time ? parseMinutesToHours(row.work_time) : '-'
            }`}
          />
        )
      }
    },
    {
      name: t('views.times.effectiveTime'),
      sortable: 'effective_time',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            children={`${
              row?.effective_time ? parseMinutesToHours(row?.effective_time) : '-'
            }`}
          />
        )
      }
    },
    {
      name: t('views.dashboard.productivity'),
      sortable: 'percent',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            className={`${row?.percent >= 50 ? 'text-green' : 'text-danger'}`}
            children={`${row?.percent} %`}
          />
        )
      }
    }
  ]

  const {
    data: contractorDetailTimes,
    isLoading: contractorDetailTimesIsLoading,
    refetch: detailTableRefetch
  } = useQuery(
    [
      'getContractorDetailTimes',
      currentPage,
      limit,
      queries,
      sortBy,
      shouldUpdate,
      date
    ],
    () =>
      API.getContractorDetailTimes({
        offset: currentPage,
        limit,
        order: sortBy,
        query: queries,
        date
      })
  )

  useSocket(
    disabledRefetchSocket(date)
      ? {
          '/wallet/time/pairs': {
            fn: value => {
              detailTableRefetch()
            }
          }
        }
      : {},
    [date]
  )

  const handlePageChange = ({ page, limit, query, sort }) => {
    setCurrentPage(page)
    setLimit(limit)
    setQueries(query)
    if (sort) setSortBy(sort)
  }
  return (
    <>
      <TimesDetailGraphs date={date} />
      <JpTableV1
        cols={tabCols}
        fetcher={handlePageChange}
        data={contractorDetailTimes?.data}
        total={contractorDetailTimes?.count}
        loading={contractorDetailTimesIsLoading}
        actions={<></>}
        statusChange={shouldUpdate}
      />
    </>
  )
}

export default TimesDetail
