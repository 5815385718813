import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFeedback } from 'reactstrap'
import { JpTextAreaInput } from '@Intelli/utilities'

const JpTextAreaController = ({
  name,
  title,
  control,
  errors = {},
  optionsController = {},
  optionsInput = {},
  maxStringLength=200,
  ns = 'errors'
}) => {
  const { t } = useTranslation()
  return (
      <>
          <Controller
              name={name}
              control={control}
              {...optionsController}
              render={({ field }) => (
                  <JpTextAreaInput
                      maxStringLength={maxStringLength}
                      title={title}
                      name={name}
                      optionsInput={{ ...optionsInput, ...field }}
                      invalid={!!errors[name]}
                  />
              )}
          />
          <FormFeedback invalid>
              {t(errors[name]?.message, { ns })}
          </FormFeedback>
      </>
  )
}

export default JpTextAreaController
