import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

export const isAllDataProject = createSlice({
    name: 'isAllDataProject',
    initialState,
    reducers: {
        setIsAllDataProject: (state, action) => {
            return (state = action.payload)
        }
    }
})

export const { setIsAllDataProject } = isAllDataProject.actions

export default isAllDataProject.reducer
