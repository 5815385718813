// ** React Imports
import { Outlet } from 'react-router-dom'
import Layout from '@layouts/HorizontalLayout'
import navigation from '@src/navigation/horizontal'
import { Col, Row } from 'reactstrap'
import logo from '@src/assets/images/logo/logo_white_horizontal.svg'
import CustomNavbar from '../components/navbar/CustomNavbar'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import WalletAPI from '../services/API'
import { useQuery } from '@tanstack/react-query'
import { user$, getUserOperatingSystem } from '@Intelli/utilities'
import { downloadFile } from '@src/utility/Files'
import DownloadApp from '../components/modals/DownloadApp'

const CustomFooter = () => {
  const { i18n, t } = useTranslation()
  const [modalDownload, setModalDownload] = useState(false)
  const API = new WalletAPI()
  const os = getUserOperatingSystem()
  const { data: appsDataArray, isLoading: appsDataArrayIsLoading } = useQuery(
    ['appsDataArray'],
    () => API.getDashboardApps()
  )
  const appToDownload = appsDataArray?.find(i => i.name === os.toUpperCase())
  return (
    <>
      <DownloadApp
        modalDownload={modalDownload}
        setModalDownload={setModalDownload}
      />
      <Row className="pt-50 pb-50">
        <img
          className="border-end pe-1 border-2"
          style={{ maxWidth: '135px' }}
          src={logo}
          alt="logo"
        />
        <Col>
          <div>
            <small className="footer-text">
              {t('Footer-TextOne')} {new Date().getFullYear()}
              {t('Footer-TextTwo')}
            </small>
          </div>
        </Col>
        <Col className="text-end">
          <small
            onClick={() =>
              window.location.href = appToDownload?.url
            }
            className="footer-text seeDoc cursor-pointer"
          >
            {t('downloadApp')}
          </small>
          {/* {i18n.language !== 'es' ? (
            <Link
              target="_blank"
              className="footer-text seeDoc cursor-pointer text-white"
              to={'https://joobpay.com/user-manual/'}
            >
              
              {t('userManual')}
            </Link>
          ) : (
            <Link
              target="_blank"
              className="footer-text seeDoc cursor-pointer text-white"
              to={'https://joobpay.com/es/manual-de-usuario/'}
            >
              
              {t('userManual')}
            </Link>
          )} */}
        </Col>
      </Row>
    </>
  )
}

const HorizontalLayout = props => {
  const API = new WalletAPI()
  const [menuData, setMenuData] = useState([...navigation])
  useEffect(() => {
    if (!!!user$?.value?.company?.contractor) {
      setMenuData([...menuData.filter(i => i.id !== 'times')])
    }
  }, [])

  /*   let filteredMenu = [...navigation]
  if (!!!user$?.value?.company?.contractor) {
    console.log("asd");
    filteredMenu= filteredMenu.filter(i => i.id !== 'times')
  } */
  const { } = useQuery(['profileInfo'], () => API.getUserProfile(), {
    enabled: !!user$?.value?.company?.contractor,
    onSuccess: d => {
      if (!d.time_active) {
        setMenuData([...menuData.filter(i => i.id !== 'times')])
      }
    }
  })

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout
      menuData={menuData}
      footer={<CustomFooter />}
      navbar={props => <CustomNavbar menuData={menuData} {...props} />}
      {...props}
    >
      <Outlet />
    </Layout>
  )
}

export default HorizontalLayout
