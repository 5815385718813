import React from 'react'
import { Col, Row } from 'reactstrap'
import {
  parseMinutesToHours,
  JpPercentageText,
  JpGraphCard,
  JpGauge,
  JpText,
  JpVerticalBarsCard,
  JpProgressBar,
  SpinnerLoader
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import WalletAPI from '../../services/API'
import disabledRefetchSocket from '../../utility/disabledRefetchSocket'
import useSocket from '../../utility/hooks/useSocket'

const TimesDetailGraphs = ({ date }) => {
  const { t } = useTranslation()
  const API = new WalletAPI()

  /*   const { data: timeChartData, isLoading: timeChartDataisLoading } = useQuery(
    ['walletTimeDetailChart', id],
    () => API.getWalletDetailTimeChart(id)
  ) */
  const {
    data: timeProductivityDetailChart,
    isLoading: timeProductivityDetailChartIsLoading,
    refetch: chartTimeRefetch
  } = useQuery(['timeProductivityDetailChart', date], () =>
    API.getWalletProductivityDetailChart({
      date: date
    })
  )

  const {
    data: weeklyWorkedData,
    isLoading: weeklyWorkedLoading,
    refetch: weeklyDataRefetch
  } = useQuery(['weekly-worked'], () =>
    API.getContractorWeeklyWorkedHours({
      date: date
    })
  )

  useSocket(
    disabledRefetchSocket(date)
      ? {
          '/wallet/time/charts/productivity': {
            fn: value => {
              chartTimeRefetch()
            }
          }
        }
      : {},
    [date]
  )

  return (
    <Row className="mt-1 mb-2">
      <Col lg="4" sm="12" className="mt-1">
        <JpGraphCard
          title={t('views.dashboard.productivity')}
          styles={{ height: 212, marginBottom: 0 }}
          cardBodyClass="d-flex align-items-center justify-content-center"
          loading={timeProductivityDetailChartIsLoading}
          graph={
            !timeProductivityDetailChartIsLoading ? (
              <JpGauge value={timeProductivityDetailChart?.current?.variance} />
            ) : (
              <SpinnerLoader />
            )
          }
          cardFooter={
            <div className="d-flex align-items-center mt-lg-n2 justify-content-center">
              {`Total:`}
              <span className="fw-bold fs-5 ms-50 me-75">
                <JpText
                  type="span-table-text"
                  children={
                    <>{timeProductivityDetailChart?.current?.variance}%</>
                  }
                />
              </span>
              <small>
                <JpPercentageText
                  percentage={timeProductivityDetailChart?.variance?.variance}
                  periodText={t('views.times.lastDay')}
                  className="m-0"
                />
              </small>
            </div>
          }
          footerClassName={'pt-25 pb-0'}
          subFooter={
            <div className="text-center d-flex align-items-center mt-lg-n2 justify-content-center">
              {t('views.times.companyProductivity')}
              <span className="fw-bold fs-5 ms-50 me-75">
                <JpText
                  type="span-table-text"
                  children={
                    <>{timeProductivityDetailChart?.team?.productivity}%</>
                  }
                />
              </span>
              <small>
                <JpPercentageText
                  percentage={timeProductivityDetailChart?.team?.difference}
                  periodText={t('views.times.lastDay')}
                  className="m-0"
                />
              </small>
            </div>
          }
          subFooterClassName={'pb-50'}
        />
      </Col>

      <Col lg="4" sm="12" className="mt-1">
        <JpProgressBar
          styles={{ height: 212, marginBottom: 0 }}
          title={t('views.times.productivityVsTime')}
          value={Math.floor(timeProductivityDetailChart?.current?.variance)}
          minutes={parseMinutesToHours(
            timeProductivityDetailChart?.current?.productivity
          )}
          loading={timeProductivityDetailChartIsLoading}
          footer={
            <>
              <span>
                <small>
                  {t('views.times.productiveTimeThisDay')}
                  {': '}
                  {parseMinutesToHours(
                    timeProductivityDetailChart?.current?.productivity
                  )}
                </small>
              </span>
              <small className="d-flex">
                <JpPercentageText
                  percentage={parseFloat(
                    timeProductivityDetailChart?.variance?.variance
                  )}
                />
                {t('views.times.lastDay')}
              </small>
            </>
          }
        />
      </Col>
      <Col lg="4" sm="12" className="mt-1">
        <JpVerticalBarsCard
          styles={{ height: 212, marginBottom: 0 }}
          bodyClassName="pt-75 pb-1 "
          headerClassName="mb-1"
          footerClassName="py-50"
          cardBodyHeight={{ height: 125 }}
          loading={weeklyWorkedLoading}
          /*           additionalInfo={
            <div className="d-flex flex-column align-items-between justify-content-between h-100">
              <CardTitle>{t('Time')}</CardTitle>
              <div>
                <span>
                  <h5 id="worked-time" className="mb-0 fw-normal mb-25">
                    {t('views.time.graphs.workedTime')}
                  </h5>
                  <UncontrolledTooltip target={'worked-time'}>
                    {t('views.time.graphs.workedTimeTool')}
                  </UncontrolledTooltip>
                  <h4 className="m-0 fw-bolder">
                    {parseMinutesToHours(weeklyWorkedData?.hours?.current)}
                  </h4>
                </span>
                <small className="d-flex my-50">
                  <JpPercentageText
                    percentage={parseFloat(weeklyWorkedData?.hours?.percent)}
                    periodText={`${t('views.time.graphs.lastPeriod')}`}
                  />
                </small>
              </div>
            </div>
          } */
          title={t('views.times.timeWorked')}
          data={weeklyWorkedData?.week
            ?.map(item => parseFloat(item.work_time))
            .reverse()}
          labels={Array.from({ length: 7 }, (_, i) =>
            moment(date).subtract(i, 'days').format('dd')
          ).reverse()}
          tooltipFormatter={value => {
            return parseMinutesToHours(value)
          }}
          isToday={moment().format('YYYY-MM-DD') === date}
          color="info"
          seriesLabel={
            moment().format('YYYY-MM-DD') === date
              ? t('today')
              : moment(date).format('dd')
          }
        />
      </Col>
    </Row>
  )
}

export default TimesDetailGraphs
