// ** Router imports
import { useRoutes } from "react-router-dom"

// ** GetRoutes
import { getRoutes } from "./routes"

// ** Hooks Imports
import { useLayout } from "@hooks/useLayout"
import { useQuery } from "@tanstack/react-query"
import { setuserInfo } from '@src/redux/userInfo'
import { useDispatch } from "react-redux"
import WalletAPI from "../services/API"
import { useEffect } from "react"

const Router = () => {
  // ** Hooks
  const { layout } = useLayout()
  const dispatch = useDispatch()
  const API = new WalletAPI()

  const allRoutes = getRoutes(layout)

    const {
      data: userData,
      isError: userHasError,
      isLoading: userIsLoading,
      isSuccess: userDataSuccess
    } = useQuery(['user'], () => API.getUser())

    useEffect(() => {
      if (userDataSuccess) {
        dispatch(setuserInfo(userData))
      }
    }, [userDataSuccess])

  const routes = useRoutes([...allRoutes])

  return routes
}

export default Router
