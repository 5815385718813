import { Navbar } from 'reactstrap'
import MenuComponent from '../../@core/layouts/components/menu/horizontal-menu'
import { useNavbarType } from '@hooks/useNavbarType'
import { useSelector } from 'react-redux'

const CustomMenu = ({ menuData }) => {
  const { navbarType } = useNavbarType()

  const layoutStore = useSelector(state => state.layout)

  // ** Vars
  const contentWidth = layoutStore.contentWidth

  const navbarClasses = {
    floating:
      contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
    sticky: 'fixed-top'
  }

  return (
    <div className="horizontal-menu-wrapper position-sticky joobpay-navbar d-xl-block d-none">
      <Navbar
        tag="div"
        expand="sm"
        light
        className={`header-navbar navbar-horizontal menu-border`}
      >
        <MenuComponent menuData={menuData} />
      </Navbar>
    </div>
  )
}

export default CustomMenu
