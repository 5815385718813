import {
  JpTableV1,
  dateLocalizer,
  JpText,
  JpDatePicker,
  JpAvatar,
  JpTableAction,
  JpTableTitleName,
  ContractorNameInfo,
  SweetModal,
  JpButton
} from '@Intelli/utilities'
import { downloadFile } from '@src/utility/Files'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Badge, Col, Row } from 'reactstrap'
import moment from 'moment-timezone'
import { useMutation, useQuery } from '@tanstack/react-query'
import WalletAPI from '../../services/API'
import {
  faFileArrowDown,
  faTrashCan
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSocket from '../../utility/hooks/useSocket'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'
import ModifyPermissionModal from './ModifyPermissionModal'
import TimesGraphs from '../times/TimesGraphs'

const TabActions = ({
  start_date,
  setStartDate,
  end_date,
  setEndDate,
  setCurrentPage,
  onClick
}) => {
  return (
    <Row className="align-items-center justify-content-between">
      <Col
        sm="5"
        md="4"
        lg="3"
        xl="3"
        className="d-none d-lg-flex table-filters"
      >
        <JpDatePicker
          id="contractStartDate"
          value={start_date}
          setPicker={date => {
            setCurrentPage(0)
            setStartDate(date.format('YYYY-MM-DD'))
          }}
          maxDate={end_date}
          className={'enabled-joobpay'}
        />

        <JpDatePicker
          id="contractEndDate"
          value={end_date}
          minDate={moment(start_date).format('YYYY-MM-DD')}
          setPicker={date => {
            setCurrentPage(0)
            setEndDate(date.format('YYYY-MM-DD'))
          }}
          className={'enabled-joobpay ms-1'}
        />
      </Col>
      <Col lg="5" xl="1" className="d-flex justify-content-end me-1 mt-1 mt-md-0">
        <JpButton
          id="add"
          type="add"
          tooltip
          options={{
            onClick: onClick,
            textLess: true,
            iconPosition: 'center'
          }}
        />
      </Col>
    </Row>
  )
}

const PermissionsDetails = () => {
  const [start_date, setStartDate] = useState(moment().subtract(1,'months').format('YYYY-MM-DD'))
  const [end_date, setEndDate] = useState(
    moment().add(1,'months').format('YYYY-MM-DD')
  )
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [queries, setQueries] = useState('')
  const [sortBy, setSortBy] = useState('start_date__desc')
  const [permissionModal, setPermissionModal] = useState(false)
  const [editData, setEditData] = useState(null)
  const [approvalModal, setApprovalModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)

  const API = new WalletAPI()
  const { t } = useTranslation()
  const { id } = useParams()

  const {
    data: getContractorPermissions,
    isLoading: getContractorPermissionsLoading,
    refetch: getContractorPermissionsRefetch
  } = useQuery(
    [
      'getContractorPermissions',
      currentPage,
      limit,
      start_date,
      end_date,
      queries,
      sortBy
    ],
    () =>
      API.getContractorPermissions({
        offset: currentPage,
        limit,
        date__between: `${start_date},${end_date}`,
        query: queries,
        order: sortBy
      })
  )

  useSocket(
    disabledRefetchSocket(end_date)
      ? {
          [`/time/permissions_contractors/${id}/details`]: {
            fn: value => {
              getContractorPermissionsRefetch()
            }
          }
        }
      : {},
    [end_date]
  )

  const { mutate: deletePermission } = useMutation(
    id => API.deletePermission(id),
    {
      onSuccess: () => {
        SweetModal(
          'success',
          t('Success'),
          t('views.permissions.deletePermissionSuccess'),
          t('Ok')
        )
        getContractorPermissionsRefetch()
      }
    }
  )

  const deletePermissionFunction = id => {
    SweetModal(
      'warning',
      t('views.permissions.deletePermissionTitle'),
      t('views.permissions.deletePermissionTxt'),
      t('Ok'),
      ({ isConfirmed }) => {
        if (isConfirmed) {
          deletePermission(id)
        }
      },
      { showCancelButton: true, cancelButtonText: t('CANCEL') }
    )
  }

  const stateColors = {
    EJECUTED: {
      color: 'success',
      can_delete: false
    },
    PROCESSED: {
      color: 'success',
      can_delete: false
    },
    APPROVED: {
      color: 'success',
      can_delete: false
    },
    PENDING: {
      color: 'warning',
      can_delete: true
    },
    REJECTED: {
      color: 'danger',
      can_delete: true
    }
  }

  const tabCols = [
    {
      name: (
        <JpTableTitleName
          customClass="ms-2"
          text={t('views.permissions.permission')}
        />
      ),
      sortable: 'permission_name',
      cell: row => {
        const Tag = 'div'

        return (
          <>
            <JpAvatar
              icon={<FontAwesomeIcon icon={`fa-solid fa-${row?.permission_icon}`} />}
              color={`info`}
            />
            <div
              className="user-info ms-75 text-primary cursor-pointer text-truncate seeDoc"
              onClick={() => {
                setApprovalModal(false)
                setDetailModal(true)
                setEditData({
                  contractor_permission_id: row?.id
                })
                setPermissionModal(true)
              }}
            >
              <ContractorNameInfo
                Tag={Tag}
                classNameContainer={'d-flex flex-column '}
                largeName={row?.permission_name ? row?.permission_name : '-'}
                contractor_id={row?.id}
              />
            </div>
          </>
        )
      }
    },
    {
      name: t('views.permissions.start'),
      sortable: 'start_date',
      cell: row => {
        return (
          <JpText
            type="span-table-date"
            children={
              row?.start_date
                ? dateLocalizer.getIntlLongDate(row?.start_date)
                : '-'
            }
          />
        )
      }
    },
    {
      name: t('views.permissions.end'),
      sortable: 'end_date',
      cell: row => {
        return (
          <JpText
            type="span-table-date"
            children={
              row?.end_date ? dateLocalizer.getIntlLongDate(row?.end_date) : '-'
            }
          />
        )
      }
    },
    {
      name: t('views.permissions.days'),
      sortable: 'days',
      cell: row => {
        return (
          <JpText
            type="span-table-date"
            children={row?.days ? `${row?.days}` : '-'}
          />
        )
      }
    },
    {
      name: t('views.permissions.dayOfRequest'),
      sortable: 'created_at',
      cell: row => {
        return (
          <div>
            {
              <JpText
                type="span-table-date"
                children={
                  row?.created_at
                    ? dateLocalizer.getIntlLongDate(row?.created_at)
                    : '-'
                }
              />
            }
          </div>
        )
      }
    },
    {
      name: t('views.permissions.state'),
      sortable: 'state',
      width: '120px',
      cell: row => {
        return (
          <>
            {row?.state ? (
              <Badge color={stateColors[row?.state]?.color}>
                {t(`views.permissions.states.${row?.state}`)}
              </Badge>
            ) : (
              '-'
            )}
          </>
        )
      }
    },
    {
      name: (
        <JpTableTitleName
          customClass="me-1"
          text={t('views.permissions.actions')}
        />
      ),
      right: true,
      cell: (row, index) => {
        return (
          <>
            {row?.support_media_location && (
              <JpTableAction
                icon={faFileArrowDown}
                id={`download-${row.permission_id}`}
                tooltipText={t('views.documentation.list.actions.download')}
                onClick={() =>
                  downloadFile(
                    row?.support_media_location,
                    row?.permission_name
                  )
                }
              />
            )}
            {stateColors[row?.state]?.can_delete && (
              <>
                <JpTableAction
                  icon="fa fa-pen-to-square"
                  id={`edit-${row.id}`}
                  tooltipText={t('EDIT')}
                  onClick={() => {
                    setDetailModal(false)
                    setApprovalModal(false)
                    setEditData({
                      contractor_permission_id: row?.id
                    })
                    setPermissionModal(true)
                  }}
                />
                <JpTableAction
                  icon={faTrashCan}
                  id={`delete-${row?.id}`}
                  tooltipText={t('views.permissions.deletePermissionTitle')}
                  onClick={() => {
                    deletePermissionFunction(row?.id)
                  }}
                />
              </>
            )}
          </>
        )
      }
    }
  ]

  const handlePageChange = ({ page, limit, query, sort }) => {
    setCurrentPage(page)
    setLimit(limit)
    setQueries(query)
    if (sort) setSortBy(sort)
  }

  return (
    <>
      <TimesGraphs start_date={start_date} end_date={end_date} />
      <JpTableV1
        cols={tabCols}
        fetcher={handlePageChange}
        data={getContractorPermissions?.data}
        total={getContractorPermissions?.count}
        loading={getContractorPermissionsLoading}
        actions={
          <TabActions
            start_date={start_date}
            setStartDate={setStartDate}
            end_date={end_date}
            setEndDate={setEndDate}
            setCurrentPage={setCurrentPage}
            onClick={() => {
              setDetailModal(false)
              setApprovalModal(false)
              setEditData({})
              setPermissionModal(true)
            }}
          />
        }
      />
      <ModifyPermissionModal
        isOpen={permissionModal}
        toggle={() => setPermissionModal(!permissionModal)}
        refetch={getContractorPermissionsRefetch}
        editData={editData}
        setEditData={setEditData}
        approval={approvalModal}
        view_only={detailModal}
      />
    </>
  )
}

export default PermissionsDetails
