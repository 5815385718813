const hoursStates = {
    ABSENT: {
        name: 'ABSENT',
        color: 'light-yellow',
        iconAvatar: 'fa-solid fa-calendar-xmark'
    },
    WORKING: {
        name: 'WORKING',
        color: 'success',
        iconAvatar: 'fa-solid fa-arrow-right-to-bracket'
    },
    CLOCKED_OUT: {
        name: 'CLOCKED_OUT',
        color: 'secondary',
        iconAvatar: 'fa-solid fa-arrow-right-from-bracket'
    },
    CLOCK_OUT: {
        name: 'CLOCKED_OUT',
        color: 'secondary',
        iconAvatar: 'fa-solid fa-arrow-right-from-bracket'
    },
    HOLIDAY: {
        name: 'HOLIDAY',
        color: 'purple',
        iconAvatar: 'fa-solid fa-calendar-heart'
    },
    PERMISSION: {
        name: 'PERMISSION',
        color: 'cyan',
        iconAvatar: 'fa-solid fa-calendar-pen'
    },
    TIME_OFF: {
        name: 'TIME_OFF',
        color: 'light-blue',
        iconAvatar: 'fa-solid fa-calendar-circle-user'
    }
}

export default hoursStates