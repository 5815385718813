import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCheck,
  faUser,
  faClock
} from '@fortawesome/pro-solid-svg-icons'
import {
  JpButton,
  JpButtonGroup
} from '@Intelli/utilities'
import { useSearchParams } from 'react-router-dom'
import Markings from './times/Markings'
import Permissions from './permissions/Permissions'

const Times = () => {
  const [params, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState(
    !!params.get('page') ? parseInt(params.get('page')) : 1
  )
  const { t } = useTranslation()

  const onTabChange = index => {
    setSearchParams({
      page: index
    })
    setCurrentPage(index)
  }

  const PageBody = () => {
    if (currentPage === 1)
      return (
        <Markings />
      )
    if (currentPage === 2)
      return (
        <Permissions />
      )
  }

  return (
    <>
      <JpButtonGroup className='mt-1'>
        <JpButton
          type="group"
          text={t('markings')}
          options={{
            onClick: () => onTabChange(1),
            iconPosition: 'left',
            customIcon: (
              <FontAwesomeIcon
                icon={faClock}
                className="me-25"
              />
            )
          }}
          active={currentPage === 1}
          outline={currentPage !== 1}
        />
        <JpButton
          type="group"
          text={t('permissions')}
          options={{
            onClick: () => onTabChange(2),
            iconPosition: 'left',
            customIcon: (
              <FontAwesomeIcon
                icon="fa-solid fa-newspaper"
                className="me-25"
              />
            )
          }}
          active={currentPage === 2}
          outline={currentPage !== 2}
        />

      </JpButtonGroup>
      <PageBody />
    </>
  )
}

export default Times
