import {
  dateLocalizer,
  JpCurrencyNumber,
  JpTableV1,
  JpText,
  parseMinutesToHours,
  JpDatePicker,
  JpAvatar,
  JpTableTitleName,
  JpAvatarGroup
} from '@Intelli/utilities'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import disabledRefetchSocket from '../../utility/disabledRefetchSocket'
import moment from 'moment'
import useSocket from '../../utility/hooks/useSocket'
import { Col, Row } from 'reactstrap'
import WalletAPI from '../../services/API'
import TimesGraphs from './TimesGraphs'
import { getColorByPercentageCompleted } from '../../components/general/getColorByPercentageCompleted'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hoursStates from '../../components/constants/hoursStates'

const TabActions = ({
  start_date,
  setStartDate,
  setCurrentPage,
  setEndDate,
  end_date
}) => {
  return (
    <Row className="align-items-center justify-content-start">
      <Col
        sm="3"
        md="2"
        lg="2"
        xl="2"
        className="d-none d-xl-flex table-filters px-0"
      >
        <JpDatePicker
          id="contractStartDate"
          value={start_date}
          setPicker={date => {
            setCurrentPage(0)
            setStartDate(date.format('YYYY-MM-DD'))
          }}
          className={'enabled-joobpay me-1'}
          maxDate={end_date}
        />
        <JpDatePicker
          id="contractEndDate"
          value={end_date}
          setPicker={date => {
            setCurrentPage(0)
            setEndDate(date.format('YYYY-MM-DD'))
          }}
          className={'enabled-joobpay'}
          maxDate={moment().format('YYYY-MM-DD')}
          minDate={start_date}
        />
      </Col>
    </Row>
  )
}

const Markings = () => {
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [queries, setQueries] = useState('')
  const [sortBy, setSortBy] = useState('date__desc')
  const [start_date, setStartDate] = useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  )
  const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const { t } = useTranslation()
  const API = new WalletAPI()

  const {
    data: contractorTimes,
    isLoading: contractorTimesIsLoading,
    refetch: timesRefetch
  } = useQuery(
    [
      'contractorTimesTable',
      currentPage,
      limit,
      queries,
      sortBy,
      shouldUpdate,
      start_date,
      end_date
    ],
    () =>
      API.getContractorTimes({
        offset: currentPage,
        limit,
        order: sortBy,
        query: queries,
        date__between: `${start_date},${end_date}`
      })
  )
  const tabCols = [
    {
      sortable: false,
      width: '20px',
      cell: row => (
        <div className="d-flex align-items-end">
          <JpAvatar
            color={`light-primary`}
            content={row?.date && `${moment(row?.date).date()}`}
            imgClassName="avatar-cover cursor-default"
          />
        </div>
      )
    },
    {
      name: t('views.times.date'),
      sortable: 'date',
      minWidth: '120px',
      cell: row => (
        <div className="d-flex align-items-center justify-content-center user-info ms-50">
          {row?.date ? (
            <Link
              to={`/wallet/times/detail/${row?.date}`}
              className="d-block fw-bold text-truncate "
            >
              {dateLocalizer.getIntlLongDate(row?.date)}
            </Link>
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      name: t('views.times.clockIn'),
      sortable: 'clock_in',
      grow: 1,
      cell: row => (
        <div className="d-flex align-items-center justify-content-center">
          {row?.clock_in ? (
            <JpText
              type="span-table-date"
              children={dateLocalizer.getIntlShortTime(row?.clock_in)}
            />
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      name: t('views.times.clockOut'),
      grow: 1,
      sortable: 'clock_out',
      cell: row => (
        <div className="d-flex align-items-center justify-content-center">
          {row?.clock_out ? (
            <JpText
              type="span-table-date"
              children={dateLocalizer.getIntlShortTime(row?.clock_out)}
            />
          ) : (
            '-'
          )}
        </div>
      )
    },
    {
      name: (
        <JpTableTitleName
          text={t('views.times.timeWorked')}
          tooltipText={t('views.times.timeWorked')}
        />
      ),
      sortable: 'time_ordinary',
      minWidth: '210px',
      cell: row => {
        return (
          <JpText
            type="span-table-text"
            children={parseMinutesToHours(row?.work_time)}
          />
        )
      }
    },
    {
      name: (
        <JpTableTitleName
          text={t('views.times.hoursExtra')}
          tooltipText={t('views.times.hoursExtra')}
        />
      ),
      minWidth: '210px',
      sortable: 'time_overtime',
      cell: row => (
        <>
          <JpText
            type="span-table-text"
            children={parseMinutesToHours(row?.time_overtime)}
          />
        </>
      )
    },
    {
      name: t('amount'),
      minWidth: '140px',
      sortable: 'fee',
      cell: row => (
        <>
          {row?.fee &&
          ['CLASSIC', 'TIME'].includes(row?.contractor?.contract_type?.name) ? (
            <>
              <JpCurrencyNumber value={row?.fee} />
            </>
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      name: (
        <JpTableTitleName
          text={t('views.times.status')}
          tooltipText={t('views.times.workStatus')}
        />
      ),
      sortable: false,
      cell: (row, i) => {
        return (
          <>
            {row?.state?.length === 0 && '-'}

            <JpAvatarGroup
              key={row?.contractor_day_id}
              data={row?.state
                ?.slice(0, 4)
                .sort((a, b) => b.priority - a.priority)
                .map((state, index) => {
                  const color = hoursStates[state?.value].color
                  const icon = hoursStates[state?.value].iconAvatar

                  if (index === 3) {
                    return {
                      content: `+${row?.state?.length - 3}`,
                      id: `state-${i}-${index}`,
                      color: color,
                      imgClassName: 'shadow-none'
                    }
                  }
                  return {
                    title: t(`views.times.states.${state?.value}`),
                    icon: <FontAwesomeIcon icon={icon} />,
                    color: color,
                    id: `state-${i}-${index}`,
                    imgClassName: 'shadow-none'
                  }
                })}
            />
          </>
        )
      }
    }
  ]

  useSocket(
    disabledRefetchSocket(end_date)
      ? {
          '/wallet/time/pairs': {
            fn: value => {
              timesRefetch()
            }
          }
        }
      : {},
    [end_date]
  )
  const handlePageChange = ({ page, limit, query, sort }) => {
    setCurrentPage(page)
    setLimit(limit)
    setQueries(query)
    if (sort) setSortBy(sort)
  }
  return (
    <>
      <TimesGraphs start_date={start_date} end_date={end_date} />
      <JpTableV1
        cols={tabCols}
        fetcher={handlePageChange}
        data={contractorTimes?.data}
        total={contractorTimes?.count}
        loading={contractorTimesIsLoading}
        offset={currentPage}
        actions={
          <TabActions
            start_date={start_date}
            setStartDate={setStartDate}
            setCurrentPage={setCurrentPage}
            setEndDate={setEndDate}
            end_date={end_date}
          />
        }
        statusChange={shouldUpdate}
      />
    </>
  )
}

export default Markings
