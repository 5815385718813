// ** Custom Components
import CustomNavbarUser from './CustomNavbarUser'

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap'

import { Fragment } from 'react'

// ** Third Party Components
import { Sun, Menu } from 'react-feather'

// ** Configs
import themeConfig from '@configs/themeConfig'

import { Link } from 'react-router-dom'

const CustomNavbar = ({ menuData, setMenuVisibility }) => {
  return (
    <>
      <div className="col-md-2 bookmark-wrapper d-flex align-items-center me-4 me-md-0">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <div className="navbar-header d-xl-block d-none">
          <ul className="nav navbar-nav">
            {/* <NavItem>
                        <Link to="/" className="navbar-brand"> */}
            <div className="navbar-brand">
              <span className="brand-logo">
                <img src={themeConfig.app.appLogoImage} alt="logo" />
              </span>
              <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
            </div>

            {/*   </Link>
                    </NavItem> */}
          </ul>
        </div>
      </div>
      <CustomNavbarUser menuData={menuData} />
    </>
  )
}

export default CustomNavbar
